html {
    scroll-behavior: smooth;
}

.prlx1 {
    background-image: url('./background-01.png');
    text-align: center;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.prlx2 {
    max-height: 100%;
}

@media screen and (max-width: 700px) {
    .prlx1 {
        background-size: 150%;
        background-position: 50% 2%;
        padding-top: 8%;
    }
}

.table {
    margin-bottom: 0 !important;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

.left {
    background-image: url('./collage_2.png');
    background-size: 40%;
    background-attachment: fixed;
    background-position: 10% 50%;
    background-repeat: no-repeat;
    background-color: #212529;

    width: 50%;
    vertical-align: middle;
}

.right {
    padding: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #212529;
    color: white;
}

.top {
    padding: 0;
}

.bottom {
    background-image: url('./collage_2.png');
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: 60%;
    padding: 50px;
    padding-top: 80%;
    padding-bottom: 50px;
    background-color: #212529;
    color: white;
}