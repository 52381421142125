.resume {
    padding-top: 30px;
    padding-bottom: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 850px;
}

@media screen and (max-width: 500px) {
    .resume {
        width: 85%;
        height: 100px;
    }
}

@media screen and (max-width: 700px) {
    .resume {
        height: 500px;
    }
}

@media screen and (max-width: 1000px) {
    .resume {
        height: 700px;
    }

}