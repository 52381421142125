.container1 {
    display: block;
    color: white;
    padding: 0;
    border-top: 1px solid white;
}

@media screen and (max-width: 750px) {
    .container1 {
        flex-wrap: wrap;
    }
}

/* .table td {
    width: 50%;
    vertical-align: middle;
} */

.cell1 {
    border-top: 1px solid white;
    flex: 1;
}

.container1 a, a:hover, a:focus, a:active {
    text-decoration: none;
    display: block;
    color: white;
}

h2, h4 {
    padding-left: 10%;
}

.projectSource {
    padding: 40px;
}

.projectSource:hover {
    box-shadow: 0 0 3px white;
}
/* 
#p1 {
    padding-bottom: 94px;
} */

#p1:hover {
    background-color: #e294de;
    color: #212529
}

#p2:hover {
    background-color: #89a3e6;
    color: #212529
}

#p3:hover {
    background-color: #9179e2;
    color: #212529
}

#p4:hover {
    background-color: #4990db;
    color: #212529;
}
