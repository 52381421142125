.container {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 700px) {
    .container {
        /* flex-wrap: wrap; */
        display: list-item;
    }
    .row {
        /* border-top: 1px solid black; */
        padding: 0;
    }
    img {
        /* transform: scale(.75); */
    }
    #right {
        padding-right: 120px;
        padding-bottom: 120px;
    }
    #fs-img {
        transform: scale(.75);
        padding-bottom: 20px;
    }
    #wonder {
        transform: scale(.75);
        margin-top: -100px;
        padding-right: 30px;
    }
    #yoonique {
        /* transform: scale(.90); */
        padding-right: 180px;
    }
}

/* .row {
    display: flex;
    color: black;
} */

.cell {
    flex: 1;
    padding: 10px;
    min-width: 300px;
}

.des {
    /* padding: 10px; */
    /* max-width: 500px; */
    /* background-color: white; */
    /* margin-top: -200px; */
}

h3 {
    text-align: center;
    padding-top: 10px;
}

.popup-content {
    margin-top: -20%;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px;
}
.popup-arrow {
    color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.img:hover {
    /* box-shadow: 0px 0px 10px gray; */
    transform: scale(1.06);
    cursor: pointer;
}
/* 
img {
    width: 100%;
} */

h1 {
    padding-left: 9.5%;
}

p {
    padding-left: 10%;
    padding-right: 10%;
}

#fs-img img {
    width: 200px;
    margin-top: -40%;
}

#fs-img {
    position: relative;
    top: 0;
    /* margin-bottom: 30%;  */
    right: 0;
    z-index: 1;
}

.mr {
    position: relative;
    top: 0;
    left: 0;
}

.mp {
    position: absolute;
    top: 100px;
    left: 180px;
}