.nav-element {
    color: #9B9C9E;
    text-decoration: none;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
}

.nav-element:hover {
    color: #cdcdce;
}

.nav-element:active {
    color: white;
}

#brand {
    text-decoration: none;
    color: #e294de;
}

#brand:hover {
    color: #94e2d0;
    text-shadow: 0 0 10px black;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
}